import database from '../firebase/firebase';
import { getLocalExpenses, addLocalExpense, removeLocalExpense, editLocalExpense } from '../store/localStorage';

// ADD_EXPENSE
export const addExpense = expense => ({
	type: 'ADD_EXPENSE',
	expense
});

export const startAddExpense = (expenseData = {}) => {
	return (dispatch, getState) => {
		const uid = getState().auth.uid;
		const { description = '', note = '', amount = 0, createdAt = 0 } = expenseData;

		const expense = {
			description,
			note,
			amount,
			createdAt
		};

		if (uid === 'Anonymously') {
			return new Promise((resolve, reject) => {
				dispatch(addExpense(addLocalExpense(expense)));
				resolve();
			});
		} else {
			return database
				.ref(`users/${uid}/expenses`)
				.push(expense)
				.then(ref => {
					dispatch(
						addExpense({
							id: ref.key,
							...expense
						})
					);
				});
		}
	};
};

// REMOVE_EXPENSE
export const removeExpense = ({ id } = {}) => ({
	type: 'REMOVE_EXPENSE',
	id
});

export const startRemoveExpense = ({ id } = {}) => {
	return (dispatch, getState) => {
		const uid = getState().auth.uid;
		if (uid === 'Anonymously') {
			return new Promise((resolve, reject) => {
				removeLocalExpense(id);
				dispatch(removeExpense({ id }));
				resolve();
			});
		} else {
			return database
				.ref(`users/${uid}/expenses/${id}`)
				.remove()
				.then(snapshot => {
					dispatch(removeExpense({ id }));
				});
		}
	};
};

// EDIT_EXPENSE
export const editExpense = (id, updates) => ({
	type: 'EDIT_EXPENSE',
	id,
	updates
});

export const startEditExpense = (id, updates) => {
	return (dispatch, getState) => {
		const uid = getState().auth.uid;
		if (uid === 'Anonymously') {
			return new Promise((resolve, reject) => {
				editLocalExpense(id, updates);
				dispatch(editExpense(id, updates));
				resolve();
			});
		} else {
			return database
				.ref(`users/${uid}/expenses/${id}`)
				.update(updates)
				.then(() => {
					dispatch(editExpense(id, updates));
				});
		}
	};
};

// SET_EXPENSES
export const setExpenses = expenses => ({
	type: 'SET_EXPENSES',
	expenses
});

export const startSetExpenses = () => {
	return (dispatch, getState) => {
		const uid = getState().auth.uid;
		if (uid === 'Anonymously') {
			return new Promise((resolve, reject) => {
				dispatch(setExpenses(getLocalExpenses()));
				resolve();
			});
		} else {
			return database
				.ref(`users/${uid}/expenses`)
				.once('value')
				.then(snapshot => {
					const expenses = [];
					snapshot.forEach(childSnapshot => {
						expenses.push({
							id: childSnapshot.key,
							...childSnapshot.val()
						});
					});

					dispatch(setExpenses(expenses));
				});
		}
	};
};
